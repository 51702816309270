<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showDeleteConfirmationDialog"
      width="50%"
      class="check-in-use-dialog"
      style="z-index: 9999999"
    >
      <slot>
        <div>
          <span>{{ description }}</span>
          <el-tree
            :data="contentUsages"
            :props="defaultProps"
            accordion
            style="margin-top: 15px; margin-bottom: 22px;"
          >
          </el-tree>
          <span slot="footer" class="dialog-footer">
            <el-button @click="showDeleteConfirmationDialog = false">{{
              __("Cancel")
            }}</el-button>
            <el-tooltip
              class="item"
              effect="dark"
              content="Force Delete might impact the associated tasks"
              placement="bottom"
              v-if="showForceDelete"
            >
              <div style="display: inline-block;margin-left: 10px;">
                <el-button type="primary" @click="handleForceDelete">{{
                  __("Force Delete")
                }}</el-button>
              </div>
            </el-tooltip>
          </span>
        </div>
      </slot>
    </el-dialog>
  </div>
</template>
<script>
import { EventBus } from "@/EventBus";
import { mapActions } from "vuex";
import _ from "lodash";
export default {
  data() {
    return {
      showDeleteConfirmationDialog: false,

      contentUsages: [],
      defaultProps: {
        children: "children",
        label: "label"
      },
      currentContentModel: ""
    };
  },

  props: {
    showForceDelete: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false,
      default: __("Are you sure about deleting this content?")
    },
    description: {
      type: String,
      required: false,
      // eslint-disable-next-line
      default: __("This content is getting used in some places. Deleting this content might break the associated tasks.")
    },
    deleteConfirmationEvent: {
      required: false,
      type: String,
      default: "open-delete-confirmation-dialog-box"
    }
  },

  computed: {
    contentUsageCount() {
      return _.size(this.contentUsages);
    }
  },

  created() {
    EventBus.$on(
      this.deleteConfirmationEvent,
      async ({ content_id, content_model, content }) => {
        this.currentContentData = content;
        this.currentContentModel = content_model;

        await this.checkContentInUse(content_model, content_id)
          .then(() => {
            if (this.contentUsageCount < 1) {
              this.handleForceDelete();
            } else {
              this.showDeleteConfirmationDialog = true;
            }
          })
          .catch(err => console.log(err));
      }
    );
  },

  beforeDestroy() {
    EventBus.$off(this.deleteConfirmationEvent);
  },

  methods: {
    ...mapActions("folders", {
      checkInUse: "checkInUse"
    }),

    checkContentInUse(content_model, content_id) {
      return new Promise((resolve, reject) => {
        this.contentUsages = [];
        this.checkInUse({
          content_model,
          content_id
        })
          .then(data => {
            this.contentUsages = [...data];
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    handleForceDelete() {
      this.showDeleteConfirmationDialog = false;
      EventBus.$emit("initiate-force-delete-" + this.currentContentModel, {
        content: this.currentContentData
      });
    }
  }
};
</script>

<style lang="scss">
.check-in-use-dialog {
  .el-dialog__body {
    min-height: 100px;
  }
}
</style>
