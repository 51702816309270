var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","display":"flex","flex-direction":"column","align-items":"center"},on:{"click":_vm.handleClear}},[_c('div',{staticStyle:{"width":"100%","position":"relative"}},[_c('page-header',{staticStyle:{"padding":"20px 30px 0 30px","width":"calc(100% - 45px)"},attrs:{"title":_vm.__('Authentication'),"navItems":_vm.navItems,"loading-state":_vm.loadingTabs},on:{"navItemChanged":_vm.handleNavItemChanged}}),(this.auth_profile_type !== 'OAuth2')?_c('div',[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(this.canWrite()),expression:"this.canWrite()"}],staticClass:"createAuthProfileButton",attrs:{"disabled":!this.canWrite(),"type":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.createAuthProfileItem.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.createAuthTextByType())+" ")])],1):_c('div',[_c('el-dropdown',{directives:[{name:"show",rawName:"v-show",value:(this.canWrite()),expression:"this.canWrite()"}],staticClass:"createAuthProfileDropDown",attrs:{"split-button":"","type":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.createAuthProfileItem('oauth2_cc')},"command":_vm.createAuthProfileItem}},[_vm._v(" "+_vm._s(_vm.createAuthTextByType())+" "),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"oauth2_cc"}},[_vm._v(_vm._s(_vm.__("New Client Credentials Grant"))+" ")]),_c('el-dropdown-item',{attrs:{"command":"oauth2_rp"}},[_vm._v(_vm._s(_vm.__("New Password Credentials Grant"))+" ")]),_c('el-dropdown-item',{attrs:{"command":"oauth2_jwt"}},[_vm._v(_vm._s(_vm.__("New Jwt Bearer Grant"))+" ")])],1)],1)],1),_c('div',{staticStyle:{"padding":"0 30px"}},[_c('el-form',{staticClass:"form-container"},[_c('PaginationToolbar',{attrs:{"sort-by-options":_vm.sortByOptions,"content-api":_vm.contentAPI,"show-slot":true,"auto-refresh-content":!_vm.modalCoverContent &&
              _vm.auth_profile_type !== 'Basic' &&
              _vm.auth_profile_type !== 'Certificate',"refresh-interval":60000},on:{"success":function($event){return _vm.$emit('replaceContent', $event)}}})],1),_c('el-scrollbar',{attrs:{"native":true}},[_c('div',{staticClass:"contentListContainer"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"},{name:"show",rawName:"v-show",value:(_vm.can('manage.auth-profiles.read')),expression:"can('manage.auth-profiles.read')"}],ref:"authProfilesTable",staticClass:"list-table",attrs:{"data":_vm.authProfiles,"highlight-current-row":""},on:{"row-click":_vm.handleSingleClick,"row-dblclick":_vm.handleDoubleClick}},[_c('el-table-column',{attrs:{"label":_vm.__('Name'),"min-width":30},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.auth_profile_name))])]}}])}),(_vm.auth_profile_type === 'OAuth2')?_c('el-table-column',{attrs:{"label":_vm.__('Status'),"min-width":20},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row.auth_profile_token)?[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(scope.row.auth_profile_token.data.message)?_c('el-tooltip',{attrs:{"content":scope.row.auth_profile_token.data.message,"placement":"top"}},[_c('div',{style:(_vm.getStatusStyles(
                          scope.row.auth_profile_token.data.status
                        ))})]):_c('div',[_c('div',{style:(_vm.getStatusStyles(
                          scope.row.auth_profile_token.data.status
                        ))})]),_c('span',[_vm._v(_vm._s(_vm.formatStatus(scope.row.auth_profile_token.data.status)))])],1)]:undefined}}],null,true)}):_vm._e(),(_vm.auth_profile_type === 'OAuth2')?_c('el-table-column',{attrs:{"label":_vm.__('Token'),"min-width":20},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row.auth_profile_token)?[(scope.row.auth_profile_token.data.token)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',[_c('copier',{attrs:{"value":scope.row.auth_profile_id.toString(),"value-from-server":_vm.getAuthProfileToken,"success-message-override":_vm.__('Token copied to clipboard successfully'),"error-message-override":_vm.__('Failed to copy token')}})],1)]):_vm._e()]:undefined}}],null,true)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.__('Description'),"min-width":70},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.auth_profile_notes))])]}}])})],1)],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }