<template>
  <div class="authFieldsTable">
    <el-form>
      <el-table fit :data="authFields" style="width: 100%">
        <el-table-column prop="type">
          <template slot-scope="scope">
            <el-form-item
              :label="__('Field Type')"
              prop="field_type"
              :class="getClass(scope.row.field_type)"
            >
              <el-select
                :value="scope.row.field_type"
                @input="updateFieldType($event)(scope.row)"
                :placeholder="__('Select Type')"
              >
                <el-option
                  v-for="item in fieldTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column prop="key">
          <template slot-scope="scope">
            <el-form-item
              :label="__('Key')"
              prop="key"
              :class="getClass(scope.row.key)"
            >
              <!--            <input-variable-popper-->
              <!--              v-if="useVariablesInKey"-->
              <!--              :is-text-area="false"-->
              <!--              v-model="scope.row.key"-->
              <!--              :include-secure-variables="useSecureVariables"-->
              <!--              force-reinitialize-->
              <!--            />-->
              <el-input
                v-model="scope.row.key"
                :placeholder="__('Select Key')"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column prop="value">
          <template slot-scope="scope">
            <el-form-item
              :label="__('Value')"
              prop="value"
              :class="getClass(scope.row.value)"
            >
              <!--            <input-variable-popper-->
              <!--              v-if="useVariablesInValue"-->
              <!--              :is-text-area="false"-->
              <!--              :include-secure-variables="useSecureVariables"-->
              <!--              v-model="scope.row.value"-->
              <!--              force-reinitialize-->
              <!--            />-->
              <el-input
                type="password"
                :show-password="false"
                v-model="scope.row.value"
                :placeholder="__('Select Value')"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column class-name="cell-item-pointer" width="50px">
          <template slot-scope="scope">
            <span @click="removeAuthFields(scope.$index)">
              <i class="el-icon-close"></i>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-form>

    <template>
      <el-button
        icon="el-icon-plus"
        text
        class="addNewBtn"
        @click="addNewAuthFields"
        >{{ __("Add Another Field") }}
      </el-button>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import { makeRandomId } from "@/utils/mocker";
// import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
// import VariableChecks from "@/views/mixins/VariableChecks";

export default {
  // mixins: [VariableChecks],
  props: {
    value: {
      required: true,
      type: [String, Array]
    }
    // useVariablesInKey: {
    //   type: Boolean,
    //   default: true,
    //   required: false
    // },
    // useVariablesInValue: {
    //   type: Boolean,
    //   default: true,
    //   required: false
    // },
    // useSecureVariables: {
    //   type: Boolean,
    //   default: true,
    //   required: false
    // }
  },
  components: {
    // InputVariablePopper
  },
  data() {
    const empty_field = {
      field_type: "",
      key: "",
      value: "",
      id: makeRandomId(8)
    };
    return {
      // convertedToArray: false,
      new_auth_field: _.cloneDeep(empty_field),
      auth_fields: [],
      fieldTypeOptions: [
        {
          value: "header",
          label: __("Header")
        },
        {
          value: "payload",
          label: __("Payload")
        },
        {
          value: "query",
          label: __("Query String")
        }
      ],
      firstInitialization: true
    };
  },
  computed: {
    // classes() {
    //   return (row, key) => {
    //     if (!row[key]) {
    //       return "no-red-highlight";
    //     } else {
    //       let hasError = this.hasInvalidVariable(
    //         row[key],
    //         key === "key" ? this.useVariablesInKey : this.useVariablesInValue
    //       );
    //       if (!hasError) {
    //         return "no-red-highlight";
    //       }
    //     }
    //   };
    // },

    // hasInvalidVariable() {
    //   return (value, checkForInvalidVariable = true) => {
    //     let invalidVariables = this.invalidVariableIdsPresentInAValue(
    //       value,
    //       checkForInvalidVariable
    //     );
    //     return _.isEmpty(invalidVariables)
    //       ? ""
    //       : __("invalid variable, :invalidVariables", {
    //           invalidVariables: invalidVariables[0]
    //         });
    //   };
    // },

    authFields: {
      get() {
        return this.auth_fields;
      },
      set(val) {
        this.auth_fields = val;
      }
    },

    authFieldJson() {
      let auth_fields = [...this.auth_fields];
      if (!auth_fields.length) {
        // if (this.convertedToArray) {
        //   return "[]";
        // }
        return auth_fields;
      }

      let params = _.map(auth_fields, auth_field => {
        return {
          ...auth_field,
          field_type: auth_field.field_type.toString().trim(),
          key: auth_field.key.toString().trim(),
          value: auth_field.value.toString().trim(),
          id: auth_field.id || makeRandomId(8)
        };
      });

      return JSON.stringify(params);
    }
  },
  methods: {
    addNewAuthFields() {
      this.firstInitialization = true;
      this.auth_fields.push({ ...this.new_auth_field });
    },
    removeAuthFields(index) {
      this.auth_fields.splice(index, 1);
    },

    updateFieldType(value) {
      return row => {
        row.field_type = value;
      };
    },

    getClass(item) {
      return {
        "is-error": !item && !this.firstInitialization,
        "is-required": !item
      };
    },

    validateAuthForm() {
      this.firstInitialization = false;
      return !!this.auth_fields.every(
        field => field.field_type && field.key && field.value
      );
    },

    initializeAuth(params) {
      if (!Array.isArray(params)) {
        // this.convertedToArray = true;
        params = Object.assign({}, JSON.parse(params));
      } else {
        params = Object.assign({}, params);
      }

      if (_.isEmpty(params)) {
        this.addNewAuthFields();
      } else {
        this.authFields = _.map(params, param => {
          return param;
        });
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "initializeAuth"
    },
    // authFields: {
    //   immediate: true,
    //   deep: true,
    //   handler(val) {
    //     let invalidVariablesFoundInKey =
    //       this.useVariablesInKey &&
    //       _.some(val, authField =>
    //         this.hasInvalidVariable(authField.key, this.useVariablesInKey)
    //       );
    //     let invalidVariablesFoundInValue =
    //       this.useVariablesInValue &&
    //       _.some(val, authField =>
    //         this.hasInvalidVariable(authField.value, this.useVariablesInValue)
    //       );
    //     this.$emit(
    //       "invalid-variables",
    //       invalidVariablesFoundInKey || invalidVariablesFoundInValue
    //     );
    //   }
    // },

    authFieldJson(newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";

div ::v-deep .el-table--border::after,
div ::v-deep .el-table--group::after,
div ::v-deep .el-table::before {
  background-color: transparent;
}

.authFieldsTable {
  ::v-deep .no-red-highlight .el-input__inner,
  ::v-deep .no-red-highlight .el-textarea__inner,
  ::v-deep .empty-row .el-input__inner,
  ::v-deep .empty-row .el-textarea__inner,
  ::v-deep .non-empty-val .el-input__inner,
  ::v-deep .non-empty-val .el-textarea__inner {
    border-color: $--border-color-base !important;

    &:focus {
      border-color: $--color-text-regular !important;
    }
  }

  ::v-deep el-table::before {
    background-color: white !important;
  }

  ::v-deep .el-table--medium th {
    padding: 0;
  }

  ::v-deep .el-table__header {
    display: none;
  }

  ::v-deep .el-form-item__error {
    padding-top: 1px;
    font-size: 10px;
  }

  ::v-deep .el-form-item__label {
    padding-left: 2px;
    padding-bottom: 4px;
    font-size: 14px;
  }
}

.addNewBtn {
  border-color: $--border-color-base !important;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 12px 10px 12px;

  ::v-deep i {
    font-size: 14px;
    font-weight: bolder;
  }

  ::v-deep span {
    margin-bottom: 6px;
  }
}
</style>
