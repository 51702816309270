<template>
  <div>
    <el-upload
      v-if="!fileUrl"
      class="file-uploader"
      :headers="headers"
      :action="getUploadUrl"
      :data="formData"
      :show-file-list="true"
      :on-success="handleSuccess"
      :on-error="handleError"
      :before-upload="beforeFileUpload"
      :on-change="handleChange"
      :limit="1"
      :multiple="false"
      drag
    >
      <UploaderInfoIcon />
      <div class="el-upload__tip" slot="tip">
        {{ this.tipText }}
      </div>
    </el-upload>

    <div
      v-if="fileName"
      style="display: flex;align-items: center;justify-content: flex-start"
    >
      <div
        style="height: 120px; width: 140px; display: flex;align-items: center;justify-content: center;border-radius: 6px; border: var(--theme-color) 1px dashed"
        v-if="fileName"
      >
        <div
          style="display: flex; flex-direction: column; align-items: center; justify-content: center"
        >
          <el-popover placement="top-start" trigger="hover">
            {{ fileName$ }}
            <div
              slot="reference"
              style="max-width: 100px; text-overflow: ellipsis ;white-space: nowrap;
              overflow: hidden;font-size: 0.8em; margin-top: 5px"
            >
              <span class="uploaded-file-name">{{ fileName$ }}</span>
            </div>
          </el-popover>
          <div style="padding-top: 5px;">
            <el-button
              @click="handleDelete"
              size="mini"
              type="danger"
              icon="el-icon-delete"
              circle
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUploader from "./../BaseUploader";
import UploaderInfoIcon from "../UploaderInfoIcon.vue";

export default {
  name: "CustomFileUploader",
  mixins: [BaseUploader],
  components: {
    UploaderInfoIcon
  },
  props: {
    fileName: {
      required: false,
      type: String
    },
    tipText: {
      required: false,
      type: String,
      default:
        "cert, txt, pdf, jpeg, csv, json, mp3 or wav file with a size less than 2mb"
    }
  },
  data() {
    return {
      certExtensionsAllowed: ["cert", "key", "pem", "crt", "csr"]
    };
  },

  methods: {
    handleChange(file, fileList) {
      this.$emit("on-change", file, fileList);
    },
    handleDelete() {
      this.$emit("on-delete");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";
::v-deep .el-image__inner {
  vertical-align: center !important;
}
.file-uploader ::v-deep .el-upload {
  border: 1px dashed $--border-color-light;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  .el-upload-dragger {
    border-color: var(--theme-color) !important;
    width: 140px;
    height: 140px;
    padding: 5px;
  }
}
.file-uploader ::v-deep .el-upload:hover {
  border-color: var(--theme-color);
}
.file-uploader-icon {
  font-size: 28px;
  color: $--color-text-secondary;
  min-width: 125px;
  min-height: 125px;
  max-width: 125px;
  max-height: 125px;
  line-height: 125px;
  text-align: center;
}
.file {
  min-width: 125px;
  min-height: 125px;
  max-width: 150px;
  max-height: 150px;
  display: block;
}
</style>
