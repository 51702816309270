<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";
import { OAUTH2_GRANT_TYPE_JWT_PRIVATE_KEY } from "@/constants/auth-profiles/authProfiles";

export default {
  name: "AuthProfilesManageIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("authprofiles", {
      contents: state => state.authProfiles
      // todo set authprofile state
    })
  },

  methods: {
    initContent() {
      return {
        auth_profile_name: "",
        auth_profile_type: "Basic",
        auth_profile_notes: "",
        oauth2_flow: "",
        ssl: {
          ssl_cert: "",
          ssl_cert_pwd: "",
          ssl_key: "",
          ssl_key_pwd: ""
        },
        auth_profile_basic: {
          data: {
            username: "",
            password: ""
          }
        },
        auth_profile_oauth2_cc: {
          data: {
            supply_in: "header",
            auth_url: "",
            client_id: "",
            client_secret: "",
            scope: "",
            default_expiry: undefined,
            auth_profile_password_credential: {
              data: {
                username: "",
                password: ""
              }
            }
          }
        },
        auth_profile_oauth2_jwt: {
          data: {
            grant_type: OAUTH2_GRANT_TYPE_JWT_PRIVATE_KEY,
            issuer: "",
            audience: "",
            subject: "",
            signature_type: "RS256",
            key: "",
            key_path: "",
            key_url: "",
            pem_type: "PKCS#1",
            key_password: "",
            additional_claims: {},
            default_expiry: undefined
          }
        },
        auth_profile_api_key: {
          data: {
            authorization: {}
          }
        }
      };
    }
  },

  mounted() {
    this.contentType = "AuthProfile";
    this.primaryKey = "auth_profile_id";
    this.scope = "ac";
  }
};
</script>

<style lang="scss" scoped></style>
