<template>
  <div v-if="formAction" style="padding: 20px">
    <div>
      <div class="titles">{{ __("ID") }}</div>
      <div class="details">{{ contentFormInfo.auth_profile_id }}</div>
      <div class="titles">{{ __("Name") }}</div>
      <div class="details">{{ contentFormInfo.auth_profile_name }}</div>
      <div class="titles" v-if="contentFormInfo.auth_profile_notes">Notes</div>
      <div class="details" v-if="contentFormInfo.auth_profile_notes">
        {{ contentFormInfo.auth_profile_notes }}
      </div>
      <div class="titles">{{ __("Type") }}</div>
      <div class="details">
        {{ formatType(contentFormInfo.auth_profile_type) }}
      </div>
      <div v-if="isCertificate">
        <div class="titles">
          {{ __("SSL Certificate") }}
        </div>
        <div class="details">
          {{ getKeyFileName(contentFormInfo.ssl.ssl_cert) }}
        </div>
      </div>
      <div v-if="isOAuth2">
        <div class="titles">
          {{ __("OAuth2-Flow") }}
        </div>
        <div class="details">{{ contentFormInfo.oauth2_flow }}</div>
        <div
          v-if="
            contentFormInfo.oauth2_flow === 'Client Credentials Grant' ||
              contentFormInfo.oauth2_flow ===
                'Resource Owner Password Credentials Grant'
          "
        >
          <div class="titles">
            {{ __("Auth URL") }}
          </div>
          <div class="details">
            {{ contentFormInfo.auth_profile_oauth2_cc.data.auth_url }}
          </div>
          <div class="titles">
            {{ __("Client ID") }}
          </div>
          <div class="details">
            {{ contentFormInfo.auth_profile_oauth2_cc.data.client_id }}
          </div>
          <div class="titles">
            {{ __("Client Secret") }}
          </div>
          <div class="details">
            {{ "**********" }}
          </div>
          <!--          <div v-if="!isEmptyCustomHeaders(auth2CCType)">-->
          <!--            <div class="titles">-->
          <!--              {{ __("Custom Headers") }}-->
          <!--            </div>-->
          <!--            <div class="details">-->
          <!--              {{ customHeadersInfoForAuthProfile(auth2CCType) }}-->
          <!--            </div>-->
          <!--          </div>-->

          <div v-if="contentFormInfo.auth_profile_oauth2_cc.data.scope">
            <div class="titles">
              {{ __("Scope") }}
            </div>
            <div class="details">
              {{ contentFormInfo.auth_profile_oauth2_cc.data.scope }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="contentFormInfo.oauth2_flow === 'Jwt Bearer Grant'">
        <div class="titles">
          {{ getIssuerTitle() }}
        </div>
        <div class="details">
          {{ contentFormInfo.auth_profile_oauth2_jwt.data.issuer }}
        </div>

        <div class="titles">
          {{ __("Audience") }}
        </div>
        <div class="details">
          {{ contentFormInfo.auth_profile_oauth2_jwt.data.audience }}
        </div>

        <!--        <div v-if="!isEmptyCustomHeaders(auth2JWTType)">-->
        <!--          <div class="titles">-->
        <!--            {{ __("Custom Headers") }}-->
        <!--          </div>-->
        <!--          <div class="details">-->
        <!--            {{ customHeadersInfoForAuthProfile(auth2JWTType) }}-->
        <!--          </div>-->
        <!--        </div>-->

        <div v-if="showSubjectInInfoPanel()">
          <div class="titles">
            {{ __("Subject") }}
          </div>
          <div class="details">
            {{ contentFormInfo.auth_profile_oauth2_jwt.data.subject }}
          </div>
        </div>
        <div class="titles">
          {{ __("Key File") }}
        </div>
        <div class="details">
          {{
            getKeyFileName(
              contentFormInfo.auth_profile_oauth2_jwt.data.key_path
            )
          }}
        </div>
        <div class="titles">
          {{ __("Key Signature") }}
        </div>
        <div class="details">
          {{ contentFormInfo.auth_profile_oauth2_jwt.data.signature_type }}
        </div>
        <div class="titles">
          {{ __("Additional Claims") }}
        </div>
        <div style="max-width: 100%">
          <el-scrollbar>
            <vue-json-pretty
              :data="
                contentFormInfo.auth_profile_oauth2_jwt.data.additional_claims
              "
              :highlightMouseoverNode="true"
              :highlightSelectedNode="true"
              style="line-height: 20px"
            ></vue-json-pretty>
          </el-scrollbar>
        </div>
      </div>

      <!--      <el-button @click="openModal = true" class="editBtn">Edit</el-button>-->
      <!--      <el-button-->
      <!--        @click="handleDelete(contentFormInfo)"-->
      <!--        class="deleteBtn"-->
      <!--        :disabled="!this.canWrite()"-->
      <!--        >Delete-->
      <!--      </el-button>-->
      <div style="margin-top: 20px;">
        <el-dropdown
          @command="command => handleAction(command, contentFormInfo)"
          :placement="__('bottom-start')"
        >
          <div>
            <span class="wrapper">
              <el-button type="primary" class="actionBtn">
                {{ __("Actions")
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
            </span>
          </div>
          <el-dropdown-menu slot="dropdown" style="width: 200px;">
            <el-dropdown-item command="edit" :disabled="!canWrite()">
              <img
                class="action-icon"
                :src="
                  require('@/assets/icons/campaign_actions/icon_pencil.svg')
                "
              />
              <span>{{ __("Edit") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="
                auth_profile_type !== 'Basic' &&
                  auth_profile_type !== 'Certificate'
              "
              command="test"
            >
              <img
                class="action-icon"
                :src="require('@/assets/icons/campaign_actions/icon_play.svg')"
              />
              <span>Test</span>
            </el-dropdown-item>
            <el-dropdown-item command="checkInUse">
              <img
                class="action-icon"
                :src="require('@/assets/icons/campaign_actions/icon_play.svg')"
              />
              <span>{{ __("Check in use") }}</span>
            </el-dropdown-item>
            <el-dropdown-item command="delete" :disabled="!canWrite()">
              <img
                class="action-icon"
                :src="
                  require('@/assets/icons/campaign_actions/icon_trash_new.svg')
                "
              />
              <span>{{ __("Delete") }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <!--        <el-popover placement="bottom" width="200" trigger="click">-->
        <!--          <div>-->
        <!--            <el-button plain @click="handleEdit">Edit</el-button>-->
        <!--            <el-button plain @click="handleDelete(contentFormInfo)">Delete</el-button>-->
        <!--          </div>-->
        <!--          <el-button slot="reference" plain>Actions</el-button>-->
        <!--        </el-popover>-->
      </div>

      <check-item-in-use></check-item-in-use>
      <delete-item
        :show-force-delete="false"
        :title="__('Authentication in use')"
        :description="authProfileInUseDesc"
      ></delete-item>
    </div>

    <el-dialog
      v-if="openModal"
      :visible.sync="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
    >
      <div class="auth-profile-form-dialog">
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <page-header :title="__('Authentication')" :contentId="id" />
          </el-col>
        </el-row>

        <el-scrollbar :native="false">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-tabs v-model="activeTab" class="tabs">
                  <el-tab-pane :label="__('Settings')" name="setting">
                    <el-form-item prop="auth_profile_name" :label="__('Name')">
                      <el-input
                        style="width: 50%"
                        v-model="contentForm.auth_profile_name"
                      ></el-input>
                    </el-form-item>

                    <el-form-item
                      prop="auth_profile_notes"
                      :label="__('Description')"
                    >
                      <el-input
                        type="textarea"
                        :rows="4"
                        :maxlength="500"
                        :placeholder="__('Enter API Description/Notes On Use')"
                        v-model="contentForm.auth_profile_notes"
                      ></el-input>
                    </el-form-item>

                    <template v-if="isBasicAuthProfile">
                      <el-form-item
                        prop="auth_profile_basic.data.username"
                        :label="__('Username')"
                      >
                        <el-input
                          v-model="contentForm.auth_profile_basic.data.username"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        prop="auth_profile_basic.data.password"
                        :label="__('Password')"
                      >
                        <el-input
                          v-model="contentForm.auth_profile_basic.data.password"
                          type="password"
                          :show-password="false"
                        ></el-input>
                      </el-form-item>
                    </template>

                    <template v-else-if="isOAuth2">
                      <div
                        v-if="
                          contentForm.oauth2_flow ===
                            'Client Credentials Grant' ||
                            contentForm.oauth2_flow ===
                              'Resource Owner Password Credentials Grant'
                        "
                      >
                        <el-form-item
                          prop="auth_profile_oauth2_cc.data.auth_url"
                          :label="__('Auth URL')"
                        >
                          <el-input
                            v-model="
                              contentForm.auth_profile_oauth2_cc.data.auth_url
                            "
                          ></el-input>
                        </el-form-item>

                        <el-row
                          type="flex"
                          v-if="
                            contentForm.oauth2_flow ===
                              'Resource Owner Password Credentials Grant'
                          "
                        >
                          <el-form-item
                            prop="auth_profile_oauth2_cc.data.auth_profile_password_credential.data.username"
                            :label="__('Username')"
                          >
                            <el-input
                              v-model="
                                contentForm.auth_profile_oauth2_cc.data
                                  .auth_profile_password_credential.data
                                  .username
                              "
                            ></el-input>
                          </el-form-item>
                        </el-row>

                        <el-row
                          type="flex"
                          v-if="
                            contentForm.oauth2_flow ===
                              'Resource Owner Password Credentials Grant'
                          "
                        >
                          <el-form-item
                            prop="auth_profile_oauth2_cc.data.auth_profile_password_credential.data.password"
                            :label="__('Password')"
                          >
                            <el-input
                              type="password"
                              :show-password="false"
                              v-model="
                                contentForm.auth_profile_oauth2_cc.data
                                  .auth_profile_password_credential.data
                                  .password
                              "
                            ></el-input>
                          </el-form-item>
                        </el-row>

                        <el-form-item
                          prop="auth_profile_oauth2_cc.data.client_id"
                          :label="__('Client ID')"
                        >
                          <el-input
                            v-model="
                              contentForm.auth_profile_oauth2_cc.data.client_id
                            "
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          prop="auth_profile_oauth2_cc.data.client_secret"
                          :label="__('Client Secret')"
                        >
                          <el-input
                            type="password"
                            :show-password="false"
                            v-model="
                              contentForm.auth_profile_oauth2_cc.data
                                .client_secret
                            "
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          prop="auth_profile_oauth2_cc.data.default_expiry"
                          :label="__('Default Token Expiration (seconds)')"
                        >
                          <el-input-number
                            v-model="
                              contentForm.auth_profile_oauth2_cc.data
                                .default_expiry
                            "
                            controls-position="right"
                            :min="300"
                            :max="86400"
                          ></el-input-number>
                        </el-form-item>

                        <el-form-item
                          prop="auth_profile_oauth2_cc.data.custom_headers"
                          :label="__('Custom Headers')"
                        >
                          <query-params
                            :value="customHeadersForAuthProfile(auth2CCType)"
                            @input="
                              setCustomHeadersForAuthProfile($event)(
                                auth2CCType
                              )
                            "
                            :use-default-field="false"
                            :use-default-key-field="false"
                            :use-secure-variables="false"
                            :use-variables-in-key="false"
                            :use-variables-in-value="false"
                          />
                        </el-form-item>

                        <el-form-item
                          prop="auth_profile_oauth2_cc.data.auth_scope"
                          :label="__('Scope')"
                        >
                          <el-input
                            v-model="
                              contentForm.auth_profile_oauth2_cc.data.scope
                            "
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          prop="auth_profile_oauth2_cc.data.supply_in"
                          :label="__('Supply In')"
                        >
                          <el-radio-group
                            v-model="
                              contentForm.auth_profile_oauth2_cc.data.supply_in
                            "
                          >
                            <el-radio label="header"
                              >{{ __("HTTP Authorization Header") }}
                            </el-radio>
                            <el-radio label="body"
                              >{{ __("Request Body") }}
                            </el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </div>
                      <div
                        v-else-if="
                          contentForm.oauth2_flow === 'Jwt Bearer Grant'
                        "
                      >
                        <el-form-item
                          prop="auth_profile_oauth2_jwt.data.grant_type"
                          :label="__('Type')"
                          required
                        >
                          <el-select
                            v-model="
                              contentForm.auth_profile_oauth2_jwt.data
                                .grant_type
                            "
                            style="width: 50%"
                            default-first-option
                          >
                            <el-option
                              v-for="item in oauth2JWTGrantTypes"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>

                        <el-form-item
                          prop="auth_profile_oauth2_jwt.data.issuer"
                          :label="getIssuerLabel()"
                        >
                          <el-input
                            v-model="
                              contentForm.auth_profile_oauth2_jwt.data.issuer
                            "
                          ></el-input>
                        </el-form-item>

                        <el-form-item
                          prop="auth_profile_oauth2_jwt.data.audience"
                          :label="__('Audience')"
                        >
                          <el-input
                            v-model="
                              contentForm.auth_profile_oauth2_jwt.data.audience
                            "
                          ></el-input>
                        </el-form-item>

                        <el-form-item
                          prop="auth_profile_oauth2_jwt.data.default_expiry"
                          :label="__('Default Token Expiration (seconds)')"
                        >
                          <el-input-number
                            v-model="
                              contentForm.auth_profile_oauth2_jwt.data
                                .default_expiry
                            "
                            controls-position="right"
                            :min="300"
                            :max="86400"
                          ></el-input-number>
                        </el-form-item>
                        <el-form-item
                          prop="auth_profile_oauth2_jwt.data.subject"
                          :label="__('Subject')"
                          v-if="isJWTPrivateKeyGrantType"
                        >
                          <el-input
                            v-model="
                              contentForm.auth_profile_oauth2_jwt.data.subject
                            "
                          ></el-input>
                        </el-form-item>

                        <el-form-item
                          prop="auth_profile_oauth2_jwt.data.custom_headers"
                          :label="__('Custom Headers')"
                        >
                          <query-params
                            :value="customHeadersForAuthProfile(auth2JWTType)"
                            @input="
                              setCustomHeadersForAuthProfile($event)(
                                auth2JWTType
                              )
                            "
                            :use-default-field="false"
                            :use-default-key-field="false"
                            :use-secure-variables="false"
                            :use-variables-in-key="false"
                            :use-variables-in-value="false"
                          />
                        </el-form-item>

                        <el-form-item
                          prop="auth_profile_oauth2_jwt.data.key_url"
                          :label="__('Key')"
                        >
                          <custom-file-uploader
                            folder="auth_profile/"
                            encrypt-contents
                            :fileUrl="
                              contentForm.auth_profile_oauth2_jwt.data.key_url
                                ? contentForm.auth_profile_oauth2_jwt.data
                                    .key_url
                                : ''
                            "
                            :file-name="fileName"
                            fit="compact"
                            @on-success="handleSuccess"
                            @on-delete="handleRemove"
                            :allow-types="getAllowedPrivateKeyTypes()"
                            :tip-text="oAuth2fileUploadTooltip"
                          />
                        </el-form-item>
                        <el-form-item
                          prop="auth_profile_oauth2_jwt.data.signature_type"
                          :label="__('Key Signature')"
                        >
                          <el-select
                            v-model="
                              contentForm.auth_profile_oauth2_jwt.data
                                .signature_type
                            "
                            :placeholder="__('Select')"
                            default-first-option
                          >
                            <el-option
                              v-for="signature in signatureTypes"
                              :key="signature"
                              :label="signature"
                              :value="signature"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          prop="auth_profile_oauth2_jwt.data.key_password"
                          :label="__('Key Password')"
                        >
                          <el-input
                            type="password"
                            :show-password="false"
                            v-model="
                              contentForm.auth_profile_oauth2_jwt.data
                                .key_password
                            "
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          prop="auth_profile_oauth2_jwt.data.additional_claims"
                          :label="__('Additional Claims')"
                        >
                          <v-json-editor
                            v-model="
                              contentForm.auth_profile_oauth2_jwt.data
                                .additional_claims
                            "
                            :options="editorOptions"
                            @error="editorError = true"
                            :plus="false"
                            style="height: 100%"
                          ></v-json-editor>
                        </el-form-item>
                      </div>

                      <div style="margin-bottom: 15px">
                        <el-button
                          @click="testAuthProfile"
                          :disabled="testing"
                          :loading="testing"
                          class="test-connection-btn"
                          size="mini"
                          :icon="getIcon"
                          :type="testResult"
                          >{{ __("Test Authentication") }}
                        </el-button>
                      </div>
                    </template>

                    <template v-else-if="isApiKey">
                      <el-form-item
                        prop="auth_profile_api_key.data.authorization"
                        :label="__('Authorization')"
                      >
                        <authorization-fields
                          ref="authFieldsRef"
                          :value="authorizationForAPIKey"
                          @input="setAuthorizationForAPIKey($event)"
                        />
                      </el-form-item>
                    </template>
                  </el-tab-pane>

                  <el-tab-pane
                    :label="__('Resource Server Cert')"
                    name="resource_ssl"
                  >
                    <ssl-certificate
                      ref="resourceSslRef"
                      :ssl_type="'res'"
                      :ssl_cert="contentForm.ssl.ssl_cert"
                      :ssl_cert_pwd="contentForm.ssl.ssl_cert_pwd"
                      :ssl_key="contentForm.ssl.ssl_key"
                      :ssl_key_pwd="contentForm.ssl.ssl_key_pwd"
                      @update-ssl-value="updateSslValue"
                      @push-to-file-uploaded="pushToFilesUploaded"
                    >
                    </ssl-certificate>
                  </el-tab-pane>

                  <el-tab-pane
                    v-if="isOAuth2"
                    :label="__('Auth Server Cert')"
                    name="auth_ssl"
                  >
                    <ssl-certificate
                      ref="authSslRef"
                      :ssl_type="'auth'"
                      :ssl_cert="contentForm.auth_ssl.ssl_cert"
                      :ssl_cert_pwd="contentForm.auth_ssl.ssl_cert_pwd"
                      :ssl_key="contentForm.auth_ssl.ssl_key"
                      :ssl_key_pwd="contentForm.auth_ssl.ssl_key_pwd"
                      @update-ssl-value="updateSslValue"
                      @push-to-file-uploaded="pushToFilesUploaded"
                    >
                    </ssl-certificate>
                  </el-tab-pane>
                </el-tabs>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item>
                  <save-button
                    type="primary"
                    @click="submitForm"
                    class="submitBtn"
                    :disabled="!can('manage.auth-profiles.write')"
                    :primaryKey="id"
                    variant="CreateUpdate"
                  />
                  <el-button
                    @click="cleanUpFilesAndHandleCancel"
                    class="cancelBtn"
                    >{{ __("Cancel") }}
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import { mapActions, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { testConnection, validateAuthProfile } from "@/api/authprofiles";
import VJsonEditor from "v-jsoneditor";
import _ from "lodash";
import { removeFiles } from "@/api/services";
import CustomFileUploader from "@/components/uploaders/types/CustomFileUploader";
import path from "path";
import VueJsonPretty from "vue-json-pretty";
import DeleteItem from "@/components/DeleteItem";
import CheckItemInUse from "@/components/CheckItemInUse";
import SslCertificate from "@/views/manage/auth-profiles/components/sslCertificate";
import QueryParams from "@/views/build/callflow/components/node-type-forms/components/QueryParams";
import { filterRowsIfEveryKeyValueIsAbsent } from "@/utils/collection";
import {
  OAUTH2_GRANT_TYPE_CC_PRIVATE_KEY,
  OAUTH2_GRANT_TYPE_JWT_PRIVATE_KEY
} from "@/constants/auth-profiles/authProfiles";
import AuthorizationFields from "@/views/manage/auth-profiles/components/AuthorizationFields.vue";

const BASIC = "basic";
const AUTH2_CC = "cc";
const AUTH2_JWT = "jwt";

const AUTH_TYPE = {
  BASIC,
  AUTH2_CC,
  AUTH2_JWT
};

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    AuthorizationFields,
    SslCertificate,
    QueryParams,
    CheckItemInUse,
    CustomFileUploader,
    PageHeader,
    SaveButton,
    VJsonEditor,
    VueJsonPretty,
    DeleteItem
  },

  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapState("authprofiles", {
      auth_profile_type: state => state.auth_profile_type,
      prev_selected_auth_profile_type: state =>
        state.prev_selected_auth_profile_type,
      oauth2_flow: state => state.oauth2_flow
    }),

    basicType() {
      return AUTH_TYPE.BASIC;
    },

    auth2CCType() {
      return AUTH_TYPE.AUTH2_CC;
    },

    auth2JWTType() {
      return AUTH_TYPE.AUTH2_JWT;
    },

    isJWTPrivateKeyGrantType() {
      return (
        this.isJWTAuthProfile &&
        this.contentForm.auth_profile_oauth2_jwt.data.grant_type ===
          OAUTH2_GRANT_TYPE_JWT_PRIVATE_KEY
      );
    },

    isCCPrivateKeyGrantType() {
      return (
        this.isJWTAuthProfile &&
        this.contentForm.auth_profile_oauth2_jwt.data.grant_type ===
          OAUTH2_GRANT_TYPE_CC_PRIVATE_KEY
      );
    },

    customHeadersForAuthProfile: {
      get: function() {
        return type => {
          let path = this.getPathFor(type);
          return this.stringifyJsonArray(
            _.get(this.contentForm, `${path}.custom_headers`, [])
          );
        };
      }
    },

    // customHeadersInfoForAuthProfile: {
    //   get: function() {
    //     return type => {
    //       let path = this.getPathFor(type);
    //       return this.stringifyJsonArray(
    //         this.parseKeyValueFrom(
    //           _.get(this.contentFormInfo, `${path}.custom_headers`, [])
    //         )
    //       );
    //     };
    //   }
    // },

    authorizationForAPIKey() {
      return this.stringifyJsonArray(
        _.get(this.contentForm, `auth_profile_api_key.data.authorization`, [])
      );
    },

    isBasicAuthProfile() {
      return (
        this.contentForm.auth_profile_type &&
        this.contentForm.auth_profile_type === "Basic"
      );
    },

    isCCOrPCAuthProfile() {
      return (
        this.contentForm.oauth2_flow === "Client Credentials Grant" ||
        this.contentForm.oauth2_flow ===
          "Resource Owner Password Credentials Grant"
      );
    },

    isJWTAuthProfile() {
      return this.contentForm.oauth2_flow === "Jwt Bearer Grant";
    },

    keyFileIsPem() {
      return this.getKeyFileExtension === "pem";
    },

    keyFileIsJson() {
      return this.getKeyFileExtension === "json";
    },

    keyFileIsP12() {
      return this.getKeyFileExtension === "p12";
    },

    getKeyFileExtension() {
      if (this.contentForm.auth_profile_oauth2_jwt.data.key_path) {
        let keyPath = this.contentForm.auth_profile_oauth2_jwt.data.key_path;
        return keyPath.split(".").pop();
      }
      return "";
    },

    fileName() {
      return this.getTailName(
        _.get(this.contentForm, "auth_profile_oauth2_jwt.data.key_path", "")
      );
    },

    isOAuth2() {
      return _.includes(this.contentForm.auth_profile_type, "OAuth2");
    },

    isApiKey() {
      return _.includes(this.contentForm.auth_profile_type, "API_Key");
    },

    isCertificate() {
      return _.includes(this.contentForm.auth_profile_type, "Certificate");
    },

    getIcon() {
      switch (this.testResult) {
        case "default":
          return "";
        case "success":
          return "el-icon-circle-check";
        case "danger":
          return "el-icon-circle-close";
        default:
          return "";
      }
    },
    editorOptions() {
      return {
        mainMenuBar: false,
        mode: "code"
      };
    },

    /**
     * Content for the oAuth2 cert upload tooltip
     * @returns {String} Translated content for the oAuth2 file upload tooltip
     */
    oAuth2fileUploadTooltip() {
      if (this.isCCPrivateKeyGrantType) {
        // eslint-disable-next-line
        return __("pem file with a size less than 2mb");
      }
      // eslint-disable-next-line
      return __("pem, json or p12 file with a size less than 2mb");
    },

    /**
     * Content for the auth profile in use error description
     * @returns {String} Translated content for the auth profile in use error description
     */
    authProfileInUseDesc() {
      // eslint-disable-next-line
      return __("This Authentication cannot be deleted as it is getting used in some places. Deleting this Authentication might break the associated tasks.");
    }
  },

  data() {
    let validateAuthProfileName = async (rule, value, callback) => {
      let hasError = false;
      await validateAuthProfile(value)
        .then(res => {
          if (
            res.data.found &&
            value !== this.contentFormInfo.auth_profile_name
          ) {
            hasError = true;
          }
        })
        .catch(() => {
          hasError = false;
        });

      if (hasError) {
        callback(__("Authentication already exists"));
      } else {
        callback();
      }
    };

    return {
      testing: false,
      testResult: "default",
      activeTab: "setting",
      notifications: [],
      isSubmitting: false,
      authProfileTypes: ["Basic", "OAuth2", "Certificate"],
      filesUploaded: [],
      oAuth2Flows: [
        "Client Credentials Grant",
        "Resource Owner Password Credentials Grant",
        "Jwt Bearer Grant"
      ],
      oauth2JWTGrantTypes: [
        {
          label: __("JWT as Authorization Grant"),
          value: OAUTH2_GRANT_TYPE_JWT_PRIVATE_KEY
        },
        {
          label: __("JWT for Client Authentication"),
          value: OAUTH2_GRANT_TYPE_CC_PRIVATE_KEY
        }
      ],
      prevSubjectValue: "",
      rules: {
        auth_profile_name: [
          {
            required: true,
            message: __("Authentication name is required"),
            trigger: "blur"
          },
          {
            validator: validateAuthProfileName,
            trigger: "blur"
          }
        ],
        auth_profile_type: [
          {
            required: true,
            message: __("Please select an Authentication type"),
            trigger: "change"
          }
        ],
        auth_profile_basic: {
          data: {
            password: [
              {
                required: true,
                message: __("Password is required"),
                trigger: "blur"
              }
            ],
            username: [
              {
                required: true,
                message: __("Username is required"),
                trigger: "blur"
              }
            ]
          }
        },
        auth_profile_oauth2_cc: {
          data: {
            auth_url: [
              {
                required: true,
                message: __("Auth URL is required"),
                trigger: "blur"
              }
            ],
            client_id: [
              {
                required: true,
                message: __("Client ID is required"),
                trigger: "blur"
              }
            ],
            client_secret: [
              {
                required: true,
                message: __("Client secret is required"),
                trigger: "blur"
              }
            ],
            auth_profile_password_credential: {
              data: {
                username: [
                  {
                    required: true,
                    message: __("Username is required"),
                    trigger: "blur"
                  }
                ],
                password: [
                  {
                    required: true,
                    message: __("Password is required"),
                    trigger: "blur"
                  }
                ]
              }
            },
            default_expiry: [
              {
                required: true,
                message: __("Default Token Expiry is required"),
                trigger: "blur"
              }
            ]
          }
        },
        auth_profile_oauth2_jwt: {
          data: {
            issuer: [
              {
                required: true,
                message: __("Issuer is required"),
                trigger: "blur"
              }
            ],
            audience: [
              {
                required: true,
                message: __("Audience is required"),
                trigger: "blur"
              }
            ],
            key_url: [
              {
                required: true,
                message: __("Please upload the key file")
              }
            ],
            default_expiry: [
              {
                required: true,
                message: __("Default Token Expiry is required"),
                trigger: "blur"
              }
            ]
          }
        }
      },
      signatureTypes: ["RS256", "RS384", "RS512"],
      pemTypes: ["PKCS#1", "PKCS#8", "PKCS#12"],
      forceDeleteEventName: "initiate-force-delete-AuthProfile"
    };
  },

  methods: {
    ...mapActions("authprofiles", {
      createAuthProfile: "createAuthProfile",
      updateAuthProfile: "updateAuthProfile",
      deleteContentMethod: "deleteAuthProfile",
      undoDeleteContent: "undoDeleteAuthProfile",
      sendTestAuth: "sendTestAuth"
    }),

    setCustomHeadersForAuthProfile(val) {
      return type => {
        let path = this.getPathFor(type);
        this.$set(
          _.get(this.contentForm, path, {}),
          "custom_headers",
          JSON.parse(val)
        );
      };
    },

    setAuthorizationForAPIKey(val) {
      this.$set(
        _.get(this.contentForm, "auth_profile_api_key.data", {}),
        "authorization",
        JSON.parse(val)
      );
    },

    getIssuerTitle() {
      return this.contentFormInfo.auth_profile_oauth2_jwt.data.grant_type ===
        OAUTH2_GRANT_TYPE_JWT_PRIVATE_KEY
        ? __("Issuer")
        : __("Client ID");
    },

    showSubjectInInfoPanel() {
      return (
        this.contentFormInfo.auth_profile_oauth2_jwt.data.subject &&
        this.contentFormInfo.auth_profile_oauth2_jwt.data.grant_type ===
          OAUTH2_GRANT_TYPE_JWT_PRIVATE_KEY
      );
    },

    // isEmptyCustomHeaders(type) {
    //   let val = this.customHeadersInfoForAuthProfile(type);
    //   return _.isEmpty(JSON.parse(val));
    // },

    // parseKeyValueFrom(keyValueArray) {
    //   const val = {};
    //   _.each(keyValueArray, item => {
    //     val[item["key"]] = item["value"];
    //   });
    //   return val;
    // },

    stringifyJsonArray(array) {
      return Array.isArray(array) || typeof array === "object"
        ? JSON.stringify(array)
        : !array
        ? "[]"
        : array;
    },

    getPathFor(type) {
      switch (type) {
        case this.basicType:
          return "auth_profile_basic.data";
        case this.auth2CCType:
          return "auth_profile_oauth2_cc.data";
        case this.auth2JWTType:
          return "auth_profile_oauth2_jwt.data";
      }
      throw new Error(`invalid type ${type}`);
    },

    getIssuerLabel() {
      if (this.isCCPrivateKeyGrantType) {
        return __("Client ID");
      }
      return __("Issuer");
    },

    getAllowedPrivateKeyTypes() {
      if (this.isCCPrivateKeyGrantType) {
        return ["pem"];
      }
      return ["pem", "json", "p12"];
    },

    openCheckInUseDialog() {
      EventBus.$emit("open-check-in-use-modal", {
        content_model: "AuthProfile",
        content_id: this.contentForm.auth_profile_id
      });
    },

    closeAllNotifications() {
      this.notifications.forEach(notification => notification.close());
      this.notifications = [];
    },

    getKeyFileName(filePath) {
      return filePath.split("/").pop();
    },

    cleanUpFilesAndHandleCancel() {
      if (!_.isEmpty(this.filesUploaded)) {
        removeFiles(this.filesUploaded);
      }
      this.filesUploaded = [];
      if (this.auth_profile_type !== this.prev_selected_auth_profile_type) {
        EventBus.$emit("list-changed");
      }
      this.closeAllNotifications();
      this.handleCancel();
    },

    getName(p) {
      return path.basename(p);
    },

    getTailName(p) {
      let nameParts = _.split(this.getName(p), "_");
      if (nameParts.length > 1) {
        return _.tail(nameParts).join("_");
      } else {
        return nameParts.join("_");
      }
    },

    handleSuccess(res) {
      this.contentForm.auth_profile_oauth2_jwt.data.key_url = res.url;
      this.contentForm.auth_profile_oauth2_jwt.data.key_path = res.path;
      if (this.keyFileIsJson) {
        this.contentForm.auth_profile_oauth2_jwt.data.pem_type = "custom";
      } else if (this.keyFileIsP12) {
        this.contentForm.auth_profile_oauth2_jwt.data.pem_type = "PKCS#12";
      } else if (this.keyFileIsPem) {
        this.contentForm.auth_profile_oauth2_jwt.data.pem_type = "PKCS#1";
      }
      this.pushToFilesUploaded(res.path);
    },

    handleRemove() {
      this.contentForm.auth_profile_oauth2_jwt.data.key_url = "";
      this.contentForm.auth_profile_oauth2_jwt.data.key_path = "";
    },

    pushToFilesUploaded(path) {
      !_.includes(this.filesUploaded, path) && this.filesUploaded.push(path);
    },

    updateSslValue(type, path, value) {
      type === "auth"
        ? (this.contentForm.auth_ssl[path] = value)
        : (this.contentForm.ssl[path] = value);
    },

    handleAction(command, contentFormInfo) {
      if (command === "edit") {
        this.handleEdit();
      } else if (command === "delete") {
        this.handleDelete(
          contentFormInfo,
          "AuthProfile",
          this.contentForm.auth_profile_id
        );
      } else if (command === "test") {
        this.testAuthProfileConnection(true);
      } else if (command === "checkInUse") {
        this.openCheckInUseDialog();
      }
    },

    getConnectionInfo() {
      let flow_type;
      let options;
      if (this.contentForm.oauth2_flow === "Client Credentials Grant") {
        flow_type = "client";
        options = this.contentForm.auth_profile_oauth2_cc.data;
      } else if (
        this.contentForm.oauth2_flow ===
        "Resource Owner Password Credentials Grant"
      ) {
        flow_type = "resource_op";
        options = _.cloneDeep(this.contentForm.auth_profile_oauth2_cc.data);
        let credentials = this.contentForm.auth_profile_oauth2_cc.data
          .auth_profile_password_credential.data;
        options = _.merge(options, credentials);
        _.unset(options, "auth_profile_password_credential");
      } else if (this.contentForm.oauth2_flow === "Jwt Bearer Grant") {
        flow_type = "jwt";
        options = this.contentForm.auth_profile_oauth2_jwt.data;
        options = _.merge(options, {
          auth_profile_id: this.contentForm.auth_profile_id
        });
      }
      // get the ssl certificate for the auth server
      options = _.merge(options, {
        cert: _.cloneDeep(this.contentForm.auth_ssl)
      });
      return [flow_type, options];
    },

    testAuthProfileConnection(storeResult = false) {
      this.testing = true;
      if (this.contentForm.auth_profile_type === "OAuth2") {
        let [flow_type, options] = this.getConnectionInfo();
        testConnection({ flow_type, options, storeResult })
          .then(({ data }) => {
            this.testResult = data.test_status ? "success" : "danger";
            this.notifications.push(
              this.$message({
                type: data.test_status ? "success" : "error",
                message: data.test_status
                  ? __("connection established")
                  : __("failed to establish connection")
              })
            );
          })
          .catch(err => {
            this.notifications.push(
              this.$message({
                type: "error",
                message: err.message,
                duration: 7500
              })
            );
            this.testResult = "danger";
          })
          .finally(() => {
            this.testing = false;
          });
      }
    },

    testAuthProfile() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          // this should not store test result
          this.testAuthProfileConnection();
        } else {
          this.notifications.push(
            this.$message({
              type: "error",
              message: __("Missing required field values")
            })
          );
        }
      });
    },

    formatType(type) {
      return type.replace("_", " ");
    },

    cleanCustomHeaders(data, path) {
      let customHeaders = _.get(data, `${path}.custom_headers`, []);
      customHeaders = filterRowsIfEveryKeyValueIsAbsent(customHeaders, "key");
      return _.set(data, `${path}.custom_headers`, customHeaders);
    },

    cleanPayload(formData) {
      let cleanFormData = _.cloneDeep(formData);

      if (this.isOAuth2) {
        let type = this.isCCOrPCAuthProfile
          ? AUTH_TYPE.AUTH2_CC
          : this.isJWTAuthProfile
          ? AUTH_TYPE.AUTH2_JWT
          : "";

        if (type) {
          let path = this.getPathFor(type);
          cleanFormData = this.cleanCustomHeaders(cleanFormData, path);
        }
      }

      // return the cleaned form data
      return cleanFormData;
    },

    submitForm() {
      Promise.all([
        this.$refs.contentForm.validate(),
        !this.isApiKey || this.$refs.authFieldsRef.validateAuthForm()
      ])
        .then(results => {
          if (results.every(result => result)) {
            this.isSubmitting = true;
            const process =
              this.id === -1 ? this.createAuthProfile : this.updateAuthProfile;

            this.contentForm.is_enabled = 1;
            this.contentForm.ac_id = this.selectedAccountId;
            process(this.cleanPayload(this.contentForm)).then(() => {
              this.isSubmitting = false;

              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("Authentication added successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Authentication updated successfully")
                  });

              EventBus.$emit("list-changed");
              if (
                this.isOAuth2 &&
                this.contentForm.oauth2_flow.includes("Jwt")
              ) {
                let filesInUse = [
                  this.ssl_cert_path,
                  this.ssl_key_path,
                  this.contentForm.auth_profile_oauth2_jwt.data.key_path
                ];
                let filesToRemove = _.reject(this.filesUploaded, file => {
                  return filesInUse.includes(file);
                });
                removeFiles(filesToRemove);
              }
              this.filesUploaded = [];
              this.closeAllNotifications();
              this.handleCancel();
            });
          }
        })
        .catch(err => {
          this.isSubmitting = false;
          this.notifications.push(
            this.$message({
              type: "error",
              message: _.map(err, "0.message")
            })
          );
        });
    },

    initializeAuthProfile() {
      this.isSubmitting = true;
      if (this.id !== -1) {
        let oAuthMapping = {
          OAuth2_CC: {
            Client: "Client Credentials Grant",
            Resource_OP: "Resource Owner Password Credentials Grant"
          }
        };
        let oAuthFlow = this.oauth2_flow;
        if (this.isOAuth2) {
          if (_.has(this.contentForm, "auth_profile_oauth2_cc")) {
            oAuthFlow =
              oAuthMapping["OAuth2_CC"][
                this.contentForm.auth_profile_oauth2_cc.data.oauth2_flow
              ];
            // if null, then min value will be assigned.
            if (
              _.isNull(
                this.contentForm.auth_profile_oauth2_cc.data.default_expiry
              )
            ) {
              this.$set(
                this.contentForm.auth_profile_oauth2_cc.data,
                "default_expiry",
                undefined
              );
            }
          } else if (_.has(this.contentForm, "auth_profile_oauth2_jwt")) {
            oAuthFlow = "Jwt Bearer Grant";
            if (
              _.isNull(
                this.contentForm.auth_profile_oauth2_jwt.data.default_expiry
              )
            ) {
              this.$set(
                this.contentForm.auth_profile_oauth2_jwt.data,
                "default_expiry",
                undefined
              );
            }
          }
          this.$set(this.contentForm, "auth_profile_type", "OAuth2");
          this.$set(this.contentForm, "oauth2_flow", oAuthFlow);
          this.$set(this.contentFormInfo, "auth_profile_type", "OAuth2");
          this.$set(this.contentFormInfo, "oauth2_flow", oAuthFlow);
        }
        if (_.isEmpty(this.contentForm.ssl)) {
          this.$set(this.contentForm, "ssl", {
            ssl_cert: "",
            ssl_cert_pwd: "",
            ssl_key: "",
            ssl_key_pwd: ""
          });
        }
        if (_.isEmpty(this.contentForm.auth_ssl)) {
          this.$set(this.contentForm, "auth_ssl", {
            ssl_cert: "",
            ssl_cert_pwd: "",
            ssl_key: "",
            ssl_key_pwd: ""
          });
        }
      } else {
        this.$set(
          this.contentForm,
          "auth_profile_type",
          this.auth_profile_type
        );
        this.$set(this.contentForm, "oauth2_flow", this.oauth2_flow);
        this.$set(
          this.contentFormInfo,
          "auth_profile_type",
          this.auth_profile_type
        );
        this.$set(this.contentFormInfo, "oauth2_flow", this.oauth2_flow);
        this.$set(this.contentForm, "ssl", {
          ssl_cert: "",
          ssl_cert_pwd: "",
          ssl_key: "",
          ssl_key_pwd: ""
        });
        this.$set(this.contentForm, "auth_ssl", {
          ssl_cert: "",
          ssl_cert_pwd: "",
          ssl_key: "",
          ssl_key_pwd: ""
        });
      }
      this.isSubmitting = false;
    }
  },

  watch: {
    "contentForm.auth_profile_oauth2_cc.data.auth_url": {
      handler() {
        this.testResult = "default";
      }
    },
    "contentForm.auth_profile_oauth2_cc.data.client_id": {
      handler() {
        this.testResult = "default";
      }
    },
    "contentForm.auth_profile_oauth2_cc.data.client_secret": {
      handler() {
        this.testResult = "default";
      }
    },
    "contentForm.auth_profile_oauth2_jwt.data.subject": {
      immediate: true,
      handler(val) {
        if (this.isJWTPrivateKeyGrantType) {
          this.prevSubjectValue = val;
        }
      }
    },
    "contentForm.auth_profile_oauth2_jwt.data.issuer": {
      immediate: true,
      handler(val) {
        if (this.isCCPrivateKeyGrantType) {
          this.contentForm.auth_profile_oauth2_jwt.data.subject = val;
        }
      }
    },
    "contentForm.auth_profile_oauth2_jwt.data.grant_type": {
      handler(val) {
        if (val === OAUTH2_GRANT_TYPE_JWT_PRIVATE_KEY) {
          this.contentForm.auth_profile_oauth2_jwt.data.subject = this.prevSubjectValue;
        } else if (val === OAUTH2_GRANT_TYPE_CC_PRIVATE_KEY) {
          this.prevSubjectValue = this.contentForm.auth_profile_oauth2_jwt.data.subject;
          this.contentForm.auth_profile_oauth2_jwt.data.subject = this.contentForm.auth_profile_oauth2_jwt.data.issuer;
        }
      }
    },
    "contentForm.auth_profile_type": {
      immediate: true,
      handler(new_auth_profile_type) {
        if (new_auth_profile_type !== "OAuth2") {
          this.$nextTick(() => {
            this.initializeAuthProfile();
          });
        }
      }
    },
    openModal(val) {
      if (val) {
        this.initializeAuthProfile();
        this.activeTab = "setting";
        this.testResult = "default";
      }
    }
  },

  created() {
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      this.initiateDelete(content);
    });
  },

  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/node_common.scss";

.titles {
  color: #6e7681;
  font-weight: bold;
  margin-top: 10px;
}

.details {
  word-wrap: break-word;
  color: #a0a8b5;
  font-size: 0.85em;
  margin-top: 5px;
}

.submitBtn {
  height: 44px;
  background: $content-theme-color;
  border: none;

  &:hover {
    background: $content-theme-hover-color;
  }
}

.cancelBtn {
  border: none;
  height: 44px;

  &:hover {
    background: lightgrey;
    color: white;
  }
}

::v-deep .queryParamsTable {
  .el-form-item {
    width: 100%;
  }
}

.editBtn {
  margin-top: 10px;
  height: 44px;
  background: $content-theme-color;
  border: none;
  color: white;
  font-size: 0.8em;
  font-weight: bold;

  &:hover {
    color: white;
    background: $content-theme-hover-color;
  }
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
