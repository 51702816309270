<template>
  <div>
    <el-form-item prop="ssl_cert" :label="__('SSL Certificate')">
      <file-uploader
        :folder="`auth_profile/ssl/certs`"
        :file-url="sslCertPath"
        :file-name="ssl_cert_name"
        encrypt-contents
        fit="compact"
        :tip-text="fileUploadTooltip"
        @on-success="handleCertFileUploadSuccess"
        @on-delete="handleCertFileRemove"
        @on-error="handleError"
      />
    </el-form-item>

    <el-form-item prop="ssl_cert_pwd" :label="__('SSL Certificate Password')">
      <el-input
        key="ssl-cert-password"
        :placeholder="__('Password')"
        type="password"
        :show-password="true"
        autocomplete="new-password"
        v-model="sslCertPwd"
      ></el-input>
    </el-form-item>

    <el-form-item prop="ssl_key" :label="__('SSL Key')">
      <file-uploader
        :folder="`auth_profile/ssl/keys`"
        :file-url="sslKeyPath"
        :file-name="ssl_key_name"
        encrypt-contents
        fit="compact"
        :tip-text="fileUploadTooltip"
        @on-success="handleKeyFileUploadSuccess"
        @on-delete="handleKeyFileRemove"
        @on-error="handleError"
      />
    </el-form-item>

    <el-form-item prop="ssl_key_pwd" :label="__('SSL Key Password')">
      <el-input
        key="ssl-key-password"
        :placeholder="__('Password')"
        type="password"
        :show-password="true"
        autocomplete="new-password"
        v-model="sslKeyPwd"
      ></el-input>
    </el-form-item>
  </div>
</template>

<script>
import FileUploader from "@/components/uploaders/types/FileUploader";
import _ from "lodash";
import path from "path";

export default {
  name: "SslCertificate",
  components: { FileUploader },
  props: {
    ssl_type: {
      // default would be hitting the Resource server
      // the other option is the Auth server
      type: String,
      required: false,
      default: "res"
    },
    ssl_cert: {
      type: String,
      required: false,
      default: ""
    },
    ssl_cert_pwd: {
      type: String,
      required: false,
      default: ""
    },
    ssl_key: {
      type: String,
      required: false,
      default: ""
    },
    ssl_key_pwd: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    /**
     * Content for the file upload tooltip
     * @returns {String} Translated content for the file upload tooltip
     */
    fileUploadTooltip() {
      // eslint-disable-next-line
      return __("files with extension pem, crt, p12 and with size less than 2mb");
    },

    sslCertPath: {
      get() {
        return this.ssl_cert;
      },
      set(path) {
        this.$emit("update-ssl-value", this.ssl_type, "ssl_cert", path);
      }
    },

    sslKeyPath: {
      get() {
        return this.ssl_key;
      },
      set(path) {
        this.$emit("update-ssl-value", this.ssl_type, "ssl_key", path);
      }
    },

    sslCertPwd: {
      get() {
        return this.ssl_cert_pwd;
      },
      set(value) {
        this.$emit("update-ssl-value", this.ssl_type, "ssl_cert_pwd", value);
      }
    },

    sslKeyPwd: {
      get() {
        return this.ssl_key_pwd;
      },
      set(value) {
        this.$emit("update-ssl-value", this.ssl_type, "ssl_key_pwd", value);
      }
    },

    ssl_cert_name() {
      return this.sslCertPath ? this.getTailName(this.sslCertPath) : "";
    },

    ssl_key_name() {
      return this.sslKeyPath ? this.getTailName(this.sslKeyPath) : "";
    }
  },
  methods: {
    getTailName(p) {
      let nameParts = _.split(path.basename(p), "_");
      if (nameParts.length > 1) {
        return _.tail(nameParts).join("_");
      } else {
        return nameParts.join("_");
      }
    },

    handleCertFileUploadSuccess({ path }) {
      this.sslCertPath = path;
      this.$emit("push-to-file-uploaded", path);
    },

    handleKeyFileUploadSuccess({ path }) {
      this.sslKeyPath = path;
      this.$emit("push-to-file-uploaded", path);
    },

    handleCertFileRemove() {
      this.sslCertPath = "";
    },

    handleKeyFileRemove() {
      this.sslKeyPath = "";
    },

    handleError(err) {
      console.log(err);
    }
  }
};
</script>
