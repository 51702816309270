<template>
  <div>
    <el-dialog
      :title="__('Check in use')"
      :visible.sync="checkInUseDialog"
      width="50%"
      class="check-in-use-dialog"
      append-to-body
    >
      <div v-loading="checkInUseLoading">
        <el-tree
          v-if="contentUsageCount"
          :data="contentUsages"
          :props="defaultProps"
          accordion
          style="margin-top: 15px; margin-bottom: 22px;"
        >
        </el-tree>
        <div v-else-if="!checkInUseLoading && !checkInUseErrorMessage">
          {{ __("This item is not currently in use.") }}
        </div>
        <div v-else>
          {{ checkInUseErrorMessage }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import { mapActions, mapState } from "vuex";
import _ from "lodash";
export default {
  name: "CheckItemInUse",
  data() {
    return {
      checkInUseDialog: false,
      contentUsages: [],
      defaultProps: {
        children: "children",
        label: "label"
      }
    };
  },
  props: {
    checkInUseDialogEvent: {
      required: false,
      type: String,
      default: "open-check-in-use-modal"
    }
  },
  computed: {
    contentUsageCount() {
      return _.size(this.contentUsages);
    },

    ...mapState("folders", {
      checkInUseLoading: state => state.checkInUseLoading,
      checkInUseErrorMessage: state => state.checkInUseErrorMessage
    })
  },

  created() {
    EventBus.$on(
      this.checkInUseDialogEvent,
      async ({ content_id, content_model }) => {
        this.checkInUseDialog = true;
        await this.checkContentInUse(content_model, content_id);
      }
    );
  },
  beforeDestroy() {
    EventBus.$off(this.checkInUseDialogEvent);
  },
  methods: {
    ...mapActions("folders", {
      checkInUse: "checkInUse"
    }),

    checkContentInUse(content_model, content_id) {
      return new Promise((resolve, reject) => {
        this.contentUsages = [];
        this.checkInUse({
          content_model,
          content_id
        })
          .then(data => {
            this.contentUsages = [...data];
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  },
  watch: {
    checkInUseErrorMessage: {
      handler() {
        if (this.checkInUseErrorMessage) {
          this.$message({
            type: "error",
            message: this.checkInUseErrorMessage
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
.check-in-use-dialog {
  .el-dialog__body {
    min-height: 100px;
  }
}
</style>
