<template>
  <div
    style="width: 100%;display: flex; flex-direction: column; align-items: center;"
    @click="handleClear"
  >
    <div style="width: 100%; position: relative">
      <page-header
        style="padding: 20px 30px 0 30px;width: calc(100% - 45px)"
        :title="__('Authentication')"
        :navItems="navItems"
        @navItemChanged="handleNavItemChanged"
        :loading-state="loadingTabs"
      />
      <div v-if="this.auth_profile_type !== 'OAuth2'">
        <el-button
          v-show="this.canWrite()"
          :disabled="!this.canWrite()"
          class="createAuthProfileButton"
          type="primary"
          @click.stop="createAuthProfileItem"
          >{{ createAuthTextByType() }}
        </el-button>
      </div>
      <div v-else>
        <el-dropdown
          v-show="this.canWrite()"
          class="createAuthProfileDropDown"
          split-button
          type="primary"
          @click.stop="createAuthProfileItem('oauth2_cc')"
          @command="createAuthProfileItem"
        >
          {{ createAuthTextByType() }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="oauth2_cc"
              >{{ __("New Client Credentials Grant") }}
            </el-dropdown-item>
            <el-dropdown-item command="oauth2_rp"
              >{{ __("New Password Credentials Grant") }}
            </el-dropdown-item>
            <el-dropdown-item command="oauth2_jwt"
              >{{ __("New Jwt Bearer Grant") }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div style="padding: 0 30px">
        <el-form class="form-container">
          <PaginationToolbar
            :sort-by-options="sortByOptions"
            :content-api="contentAPI"
            :show-slot="true"
            @success="$emit('replaceContent', $event)"
            :auto-refresh-content="
              !modalCoverContent &&
                auth_profile_type !== 'Basic' &&
                auth_profile_type !== 'Certificate'
            "
            :refresh-interval="60000"
          >
          </PaginationToolbar>
        </el-form>
        <el-scrollbar :native="true">
          <div class="contentListContainer">
            <!--          todo change data-->
            <el-table
              ref="authProfilesTable"
              v-loading="loading"
              :data="authProfiles"
              @row-click="handleSingleClick"
              @row-dblclick="handleDoubleClick"
              highlight-current-row
              class="list-table"
              v-show="can('manage.auth-profiles.read')"
            >
              <el-table-column :label="__('Name')" :min-width="30">
                <template slot-scope="scope">
                  <span>{{ scope.row.auth_profile_name }}</span>
                </template>
              </el-table-column>

              <el-table-column
                :label="__('Status')"
                v-if="auth_profile_type === 'OAuth2'"
                :min-width="20"
              >
                <template
                  slot-scope="scope"
                  v-if="scope.row.auth_profile_token"
                >
                  <div style="display: flex; align-items: center">
                    <el-tooltip
                      :content="scope.row.auth_profile_token.data.message"
                      placement="top"
                      v-if="scope.row.auth_profile_token.data.message"
                    >
                      <div
                        :style="
                          getStatusStyles(
                            scope.row.auth_profile_token.data.status
                          )
                        "
                      ></div>
                    </el-tooltip>
                    <div v-else>
                      <div
                        :style="
                          getStatusStyles(
                            scope.row.auth_profile_token.data.status
                          )
                        "
                      ></div>
                    </div>
                    <span>{{
                      formatStatus(scope.row.auth_profile_token.data.status)
                    }}</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                :label="__('Token')"
                v-if="auth_profile_type === 'OAuth2'"
                :min-width="20"
              >
                <template
                  slot-scope="scope"
                  v-if="scope.row.auth_profile_token"
                >
                  <div
                    style="display: flex; align-items: center"
                    v-if="scope.row.auth_profile_token.data.token"
                  >
                    <span>
                      <copier
                        :value="scope.row.auth_profile_id.toString()"
                        :value-from-server="getAuthProfileToken"
                        :success-message-override="
                          __('Token copied to clipboard successfully')
                        "
                        :error-message-override="__('Failed to copy token')"
                      ></copier>
                    </span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column :label="__('Description')" :min-width="70">
                <template slot-scope="scope">
                  <span>{{ scope.row.auth_profile_notes }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PageHeader from "@/components/PageHeader";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";
import { EventBus } from "@/EventBus";
import Copier from "@/components/Copier";

export default {
  name: "AuthProfilesManageList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader,
    Copier
  },
  data() {
    return {
      sortByColumns: ["auth_profile_name", "auth_profile_id"],
      authType: "Basic",
      loadingTabs: true,
      navItems: [
        {
          displayText: __("Basic"),
          label: "Basic",
          active: true,
          icon: "icon-campaign-listbased.svg"
        },
        {
          displayText: __("Certificate"),
          label: "Certificate",
          active: true,
          icon: "icon-custom-form.svg"
        },
        {
          displayText: __("OAuth2"),
          label: "OAuth2",
          active: false,
          icon: "icon-campaign-ondemand.svg"
        },
        {
          displayText: __("API Key"),
          label: "API_Key",
          active: false,
          icon: "icon-api.svg",
          ld_key: this.$getConst("AUTH_PROFILE_API_KEY")
        }
      ]
    };
  },

  computed: {
    ...mapState("authprofiles", {
      authProfiles: state => state.authProfiles,
      loading: state => state.loading,
      auth_profile_type: state => state.auth_profile_type
    })
  },
  ...mapState("app", {
    selectedAccountId: state => state.selectedAccountId
  }),

  methods: {
    ...mapActions("authprofiles", {
      contentAPI: "getAuthProfiles",
      deleteContentMethod: "deleteAuthProfile",
      undoDeleteContent: "undoDeleteAuthProfile",
      setAuthProfileType: "setAuthProfileType",
      setOAuthFlow: "setOAuthFlow",
      getAuthProfileToken: "getAuthProfileToken"
    }),

    async showTabs() {
      for (const [index, item] of this.navItems.entries()) {
        if (item.ld_key) {
          this.navItems[index]["show"] = await this.showFeature(item.ld_key);
        }
      }
      this.loadingTabs = false;
    },

    formatStatus(status) {
      return _.upperFirst(status);
    },
    getStatusStyles(status) {
      let statusStyles = {
        width: "16px",
        height: "16px",
        "border-radius": "8px",
        "margin-right": "10px"
      };

      const statusColorMap = {
        ok: "#1CD1A1",
        error: "#F84856",
        new: "#24b2f1",
        processing: "#24b2f1"
      };

      statusStyles["background-color"] = statusColorMap[status] || "#1CD1A1";
      return statusStyles;
    },
    createAuthTextByType() {
      switch (this.auth_profile_type) {
        case "Basic":
          return __("New Basic Authentication");
        case "Certificate":
          return __("New Client Certificates Only");
        case "OAuth2":
          return __("New Client Credentials Grant");
        case "API_Key":
          return __("New API Key");
      }
    },

    handleNavItemChanged(navItem, refreshList = true) {
      this.navItems = _.map(this.navItems, navItem => {
        navItem.active = false;
        return navItem;
      });
      _.find(this.navItems, { label: navItem.label }).active = true;
      if (this.auth_profile_type !== navItem.label) {
        this.setAuthProfileType(navItem.label);
        this.authType = navItem.label;
      }
      if (refreshList) {
        EventBus.$emit("list-changed");
      }
    },
    createAuthProfileItem(command) {
      let oauth2_mappings = {
        oauth2_cc: "Client Credentials Grant",
        oauth2_rp: "Resource Owner Password Credentials Grant",
        oauth2_jwt: "Jwt Bearer Grant"
      };
      switch (this.authType) {
        case "OAuth2":
          this.setAuthProfileType("OAuth2");
          this.setOAuthFlow(oauth2_mappings[command]);
          break;
        case "Basic":
        case "Certificate":
        case "API_Key":
          this.setAuthProfileType(this.authType);
          this.setOAuthFlow("");
          break;
      }
      this.createContentItem();
    },
    handleClear() {
      this.$refs.authProfilesTable &&
        this.$refs.authProfilesTable.setCurrentRow();
      this.handleClearSelection();
    },

    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      if (this.can("manage.auth-profiles.write")) {
        this.handleEdit(null, row);
      }
    }
  },
  watch: {
    auth_profile_type: {
      immediate: true,
      handler: function(val) {
        this.handleNavItemChanged(
          {
            label: val
          },
          false
        );
      }
    },
    selectedAccountId: {
      immediate: true,
      async handler(newVal) {
        if (newVal === "all") {
          this.loadingTabs = false;
        } else {
          await this.showTabs();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";

.createAuthProfileButton {
  position: absolute;
  height: 40px;
  top: 25px;
  right: 25px;
  border: none;
  color: white;
  background: $content-theme-color;
  border-radius: 6px;

  &:hover {
    background: var(--theme-hover-color) !important;
    border-color: var(--theme-hover-color) !important;
    color: white;
  }
}

.createAuthProfileDropDown {
  position: absolute;
  top: 25px;
  right: 25px;
  border: none;
  color: white;
  background: $content-theme-color;
  border-radius: 6px;

  ::v-deep button {
    height: 40px;
    background: var(--theme-color) !important;
    border-color: var(--theme-color) !important;

    &:hover {
      background: var(--theme-hover-color) !important;
      border-color: var(--theme-hover-color) !important;
      color: white;
    }
  }

  &:hover {
    ::v-deep button {
      background: $content-theme-hover-color;
      border-color: var(--theme-hover-color) !important;
    }
  }
}
</style>
